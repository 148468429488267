import React from 'react'

function Footer() {
    return (
        <footer className="footer" >
            <p>Github repository: <a href="https://github.com/aslakihle/spoiler-free" target="_blank" rel="noopener noreferrer" >here</a></p>
        </footer>
    )
}



export default (Footer)