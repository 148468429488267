import React from 'react'

export default function About() {
    return (
        <div className="content">
            <h1>About</h1>
            <p>This is a wikipage for bookseries, where you input your own progress to not read spoilers from any books you have not read yet.</p>
        </div>
    )
}
